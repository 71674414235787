@mixin th-close-button {
  color: #888;
  cursor: pointer;
  position: absolute;

  &:hover {
    color: #666;
  }
}

@mixin th-panel {
  box-shadow: 0 4px 15px 0 rgb(0 0 0 / 20%);

  @apply rounded-xl;
}

@mixin th-dark-panel {
  @include th-panel;

  background-color: rgb(0 0 0 / 80%);
}

@mixin th-light-panel {
  @include th-panel;

  background-color: rgb(255 255 255 / 95%);
}
