@import "src/styles/variables/index";
@import "src/styles/mixins/index";

.th-side-menu {
  background: $header;
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  transition: transform 0.3s;
  width: $side-menu-width;
  z-index: 100;

  @include th-flex-column;

  .th-logo-wrapper {
    height: 54px;
    margin-bottom: 24px;
    margin-top: 8px;
    width: 100%;

    @include th-flex-center;

    svg {
      height: 38px;
      width: 35px;
    }
  }

  .th-action {
    @include th-flex-column-center;

    color: #a7a7a7;
    height: 54px;
    margin: 3px 0;
    position: relative;
    width: 100%;

    &::before {
      background: $secondary;
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      transition: all 0.3s;
      width: 0;
    }

    .th-title-wrapper {
      font-size: 10px;
      margin-top: 4px;
    }

    &.th-selected {
      .th-icon-wrapper {
        color: $secondary;
      }
    }
  }

  .th-action:hover {
    color: #eee;
  }

  .th-link-action.th-active .th-action,
  .th-action.th-selected {
    color: $secondary;
    position: relative;

    &::before {
      width: 5px;
    }

    .th-icon-wrapper,
    .th-title-wrapper {
      color: $secondary;
    }
  }

  .th-auth-lock {
    padding-bottom: 20px;
  }

  .th-auth-lock + .th-action,
  .th-auth-lock + .th-link-action {
    .th-icon-wrapper {
      visibility: hidden;
    }
  }

  .th-drop-menu {
    .th-menu-item {
      margin: 0;
      padding: 0;
    }

    .th-drop-menu-content {
      @apply rounded-xl;

      bottom: 0;
      left: calc(2px + #{$side-menu-width});
      top: unset;
    }
  }

  .th-drop-menu:not(.th-drop-menu-mobile):hover .th-menu-item,
  .th-drop-menu.th-drop-menu-mobile.th-expanded .th-menu-item,
  .th-menu-item:hover {
    background-color: unset;
    box-shadow: none;
  }

  .th-custom-actions {
    flex: 1;
  }

  .th-avatar {
    margin: auto;
  }

  .th-upgrade-button {
    background: $primary;
    color: $white;
    font-size: 12px;
    height: 33px;
    margin-top: 8px;
    width: 100%;
  }

  @media (max-width: $break-sm) {
    padding-top: $app-header-height + 10px;
    transform: translateX(-100%);
    width: $side-menu-width * 3;

    &.th-expanded {
      transform: translateX(0);
    }

    .th-action {
      flex-direction: row;
      height: 48px;

      .th-icon-wrapper {
        width: 60px;
      }

      .th-title-wrapper {
        flex: 1;
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        text-align: left;
      }
    }

    .th-logo-wrapper {
      display: none;
    }

    .th-auth-lock {
      align-items: flex-start;
      padding: 0;

      .th-icon {
        width: 60px;
      }
    }

    .th-global-actions {
      .th-action {
        height: 42px;
        margin: 0;

        .th-title-wrapper {
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
  }
}
