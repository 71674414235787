@mixin th-flex-row {
  display: flex;
  flex-direction: row;
}

@mixin th-flex-row-center {
  @include th-flex-row;

  align-items: center;
}

@mixin th-flex-column {
  display: flex;
  flex-direction: column;
}

@mixin th-flex-column-center {
  @include th-flex-column;

  align-items: center;
}

@mixin th-flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin th-absolute-full {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
