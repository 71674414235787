$primary: #6651f5;
$secondary: #20cb4c;
$brown-gray: #858585;
$text: #37474f;
$border: #d8dfe3;
$label: #666;
$black: #000;
$gray: #90a4ae;
$input-border: #eee;
$white: #fff;
$primary-gradient: linear-gradient(to left, #465ff5, #6e4ef6);
$header: #1d1d1d;
$divider: #eee;
$success: #20cb4c;
$error: #d43f51;
$info: #6651f5;
$warning: #f5a623;
$basic-role: #6651f5;
$pro-role: #40c870;
$expert-role: #3585f7;
