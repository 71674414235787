@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(4);
  }
}

.ripple {
  animation: ripple 600ms ease-in-out;
  border-radius: 50%;
  opacity: 1;
  position: absolute;
  transform: scale(0);
}
