.th-popover {
  box-shadow: 0 8px 24px 0 rgb(0 0 0 / 5%);
  pointer-events: none;
  position: fixed;
  transform: scale(0);
  transition: transform 0.3s, max-height 0.3s, visibility 0.3s;
  visibility: hidden;
  z-index: 99;

  &.th-expanded {
    pointer-events: auto;
    transform: scale(1);
    visibility: visible;
  }

  :global {
    .th-triangle-up {
      border-bottom: 8px solid rgb(255 255 255);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      height: 0;
      width: 0;
    }

    .th-triangle-down {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid rgb(255 255 255);
      height: 0;
      width: 0;
    }
  }
}
