.th-logo {
  display: inline-block;

  > .th-agent-logo {
    border-radius: 4px;
    overflow: hidden;

    &.th-hexa {
      height: 42px;
      width: 42px;
    }
  }
}
