$header-height: 60px;
$app-header-height: 50px;
$search-bar-height: 60px;
$sider-width: 425px;
$sider-wide-width: 740px;
$side-menu-width: 60px;
$break-xl: 1440px;
$break-lg: 960px;
$break-md: 720px;
$break-sm: 540px;
$break-xs: 375px;
