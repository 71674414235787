.lg-toolbar .lg-close::after {
  content: url("/icons/close.svg") !important;
}

.lg-next::before {
  content: url("/icons/forward.svg") !important;
}

.lg-prev::after {
  content: url("/icons/back.svg") !important;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: #6651f5 !important;
}
