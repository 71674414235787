@import "./fonts/inter.css";
@import "./variables/index.css";

html,
body {
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-size: 16px;
}

* {
  font-family: Inter, Helvetica, Arial, sans-serif;
}

svg {
  display: inline;
}

hr {
  @apply bg-opacity-90 border-none m-0 w-full bg-white h-px;
}

button,
button:focus,
input:focus {
  outline: none;
}

*,
*:focus,
*:active {
  outline: none;
}

a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-medium m-0;
}

p {
  margin: 0;
}

.container {
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

input::placeholder,
textarea::placeholder {
  color: #757575;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

.no-scrollbar {
  margin-bottom: 0;
  padding-bottom: 0;
  scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.maplibregl-ctrl {
  box-sizing: content-box !important;
}
