@import "src/styles/variables/index";
@import "src/styles/mixins/index";

.th-notification-wrapper {
  @include th-panel;

  background: $white !important;

  @media (max-width: $break-xs) {
    margin-bottom: 2px;
  }

  .th-close-button {
    @include th-close-button;

    border-radius: 50%;
    padding: 4px;
    right: 10px;
    top: 14px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.th-notification {
  @apply py-0.5 px-0.5 pr-2.5;

  .th-notification-content {
    @include th-flex-row;

    .th-icon-container {
      @apply ml-3 w-0 flex-1 pt-0.5;
    }

    > .th-icon {
      @apply h-6 w-6;
    }

    .th-warning-icon {
      color: $warning;
    }

    .th-success-icon {
      color: $success;
    }

    .th-error-icon {
      color: $error;
    }

    .th-info-icon {
      color: $info;
    }
  }

  .th-notification-title {
    @apply text-sm font-medium text-gray-900;
  }

  .th-notification-message {
    @apply mt-1 text-sm text-gray-500;
  }

  .th-notification-action {
    justify-content: flex-end;
    margin: 12px 0 0;

    @include th-flex-row;

    button {
      background: $primary;
      border-radius: 4px;
      color: white;
      font-size: 12px;
      padding: 4px 12px;
    }
  }
}
