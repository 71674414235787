.th-drop-menu {
  @apply relative;

  & .th-drop-menu-trigger {
    @apply cursor-pointer;
  }

  & .th-drop-menu-content {
    @apply list-none absolute pt-2 pb-4 m-0 rounded bg-white
      shadow-xl opacity-0 transition ease-out duration-100 transform translate-y-0 invisible;

    min-width: 160px;
  }

  &.th-mode-hover:hover,
  &.th-expanded {
    & .th-drop-menu-content {
      @apply opacity-100 ease-in translate-y-0.5 visible;
    }
  }

  & .th-drop-menu-item {
    @apply block cursor-pointer font-medium px-5 py-2 text-black
      text-opacity-50 whitespace-nowrap hover:bg-gray-100 hover:text-opacity-100;

    font-size: 13px;
  }
}
