.th-header {
  height: var(--header-height);
  z-index: 100;

  & .th-logo {
    height: 60px;
    width: 130px;
  }

  & .th-menu-item {
    @apply inline-block text-white font-medium px-5 py-3 rounded hover:bg-primary;

    font-size: 13px;
  }

  & .th-action-button {
    @apply inline-flex text-white text-sm font-bold border
      border-secondary rounded-full h-10 items-center justify-center;
  }

  & .th-header-menu-container {
    top: var(--header-height);
    transition: transform 0.1s, opacity 0.2s;

    & :global(.th-triangle-up) {
      border-bottom-color: var(--header);
    }
  }

  & .th-header-menu {
    @screen sm {
      height: 520px;
    }

    & .th-menu-item {
      @apply flex text-xl hover:bg-transparent;
    }

    & .th-social-icon {
      @apply text-white text-opacity-50 w-6 h-6 m-2.5 hover:opacity-80;
    }
  }
}

@media print {
  .th-header {
    display: none;
  }
}
