@import "src/styles/variables/index";
@import "src/styles/mixins/index";

$ok-button-background: $primary;
$ok-button-color: $white;
$cancel-button-color: #37474f;

.th-alert-modal {
  min-width: 300px;
  padding: 24px;

  .th-modal-body {
    @include th-flex-row;

    >.th-icon {
      height: 28px;
      margin-right: 12px;
      width: 28px;
    }

    .th-confirm-icon,
    .th-warning-icon {
      color: $warning;
    }

    .th-success-icon {
      color: $success;
    }

    .th-error-icon {
      color: $error;
    }

    .th-info-icon {
      color: $info;
    }
  }

  .th-modal-title {
    font-size: 16px;
    font-weight: 500;
  }

  .th-modal-content {
    color: rgb(0 0 0 / 65%);
    font-size: 14px;
    margin-top: 8px;
  }

  .th-modal-actions {
    @include th-flex-row;

    justify-content: flex-end;
    margin: 24px -4px 0;

    &.th-reverse-order {
      flex-direction: row-reverse;
      justify-content: flex-start;
    }

    .th-action-button {
      border-radius: 4px;
      font-size: 14px;
      margin: 0 4px;
      padding: 4px 15px;

      &:hover {
        opacity: 0.8;
      }
    }

    .th-cancel-action {
      border: 1px solid #d9d9d9;
      color: $cancel-button-color;
    }

    .th-ok-action {
      background: $ok-button-background;
      color: $ok-button-color;
    }
  }
}
