@import "src/styles/variables/index";

.th-authentication-modal {
  max-width: unset !important;
  padding: 0 !important;
}

.th-authentication {
  align-items: center;
  background: white;
  color: $text;
  display: inline-block;

  // width: 100vw;
  // height: 100vh;
  // display: flex;
  justify-content: center;

  // width: 360px;
  min-height: 100px;
  text-align: center;
  user-select: none;

  .th-button {
    background: $primary;
    border: none;
    border-radius: 3px;
    color: $white;
    cursor: pointer;
    font-size: 12px;
    font-weight: $semibold;
    padding: 6px 24px;
    text-align: center;
  }

  .th-button.th-ghost {
    background: transparent;
    border: 1px solid $border;
    color: $gray;
  }

  .th-select {
    text-align: left;
  }

  .th-form {
    max-width: 100vw;
    position: relative;
    width: 360px;

    // border: 1px solid $border;
    // box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);

    .th-logo {
      height: 32px;
      width: 120px;
    }

    .th-form-title {
      color: $text;
      display: inline-block;
      font-size: 18px;
    }

    .th-divider {
      background: $divider;
      height: 1px;
    }

    .th-link {
      color: $gray;
      font-size: 12px;
    }

    .th-social-buttons {
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;

      * {
        font-family: unset;
      }

      .th-facebook-button {
        align-items: center;
        background: #4267b2;

        // border: 1px solid #4267B2;
        border: none;
        border-radius: 2px;
        color: white;
        display: flex;
        flex-direction: row;
        font-family: Roboto, sans-serif;
        font-size: 14px;
        font-weight: 500;
        height: 42px;
        margin-top: 8px;
        padding: 0;
        text-align: center;
        width: 100%;

        img + * {
          flex: 1;
          margin-right: 10px;
          text-align: center;
        }

        .th-facebook-icon {
          height: 100%;
          margin-right: 6px;
          padding: 7px;
          width: 42px;
        }
      }
    }

    // .th-subscription {
    //   font-size: 12px;
    //   text-align: left;
    //   line-height: 16px;
    //   letter-spacing: -0.16px;

    //   .th-checkbox {
    //     margin: 0 0 0 -6px;
    //   }

    //   .th-check {
    //     padding: 4px;
    //     margin-right: 4px;
    //   }

    //   .th-checkbox-label {
    //     font-size: 14px;
    //     font-weight: 500;
    //   }

    //   p {
    //     padding: 0 2px 0 16px;
    //   }
    // }

    .th-signup-button {
      background: $secondary;
      height: 32px;
      padding: 6px 16px;
    }
  }

  .th-signin-form {
    width: 20rem;
  }
}
