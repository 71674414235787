.th-app {
  @apply flex flex-col w-full max-w-full min-h-full;

  min-height: calc(var(--vh, 1vh) * 100);
}

.th-app-content {
  @apply flex-1 flex flex-col transition-transform duration-300;

  &.th-app-mode {
    @media (min-width: 540px) {
      margin-left: var(--side-menu-width);
    }

    @media (max-width: 540px) {
      margin-top: var(--app-header-height);
    }
  }
}

@media screen {
  .th-header + .th-app-content > :not(.th-no-header-space):first-child {
    margin-top: var(--header-height);
  }

  .th-app-header + .th-app-content > :not(.th-no-header-space):first-child {
    margin-top: var(--app-header-height);
  }
}

@media (max-width: 540px) {
  body.th-disable-scroll {
    @apply fixed overflow-hidden inset-0 !important;
  }
}

@media (max-width: 540px) {
  body.th-mapview-showed {
    @apply overflow-hidden;
  }

  body.th-sider-showed {
    @apply overflow-auto relative;
  }

  body.th-filters-showed {
    @apply fixed overflow-hidden inset-0;
  }
}
